import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { Admin, AdminCredentials } from "src/models/admin";
import axios from '../axios';
import { setAdmin } from "./authSlice";

export const addAdminAccount = createAsyncThunk(
  'auth/addAdminAccount',
  async (details: Admin, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.post('/admin', details);
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const changeAdminActiveness = createAsyncThunk(
  'auth/changeAdminActiveness',
  async ({ is_active, id }: { is_active: boolean, id: number }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.put(`/admin/activeness/${id}`, { is_active });
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const deleteAdminById = createAsyncThunk(
  'auth/deleteAdminById',
  async (id: number, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.delete(`/admin/${id}`);
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const changeAdminPassword = createAsyncThunk(
  'auth/changeAdminPassword',
  async ({ old_password, password }: AdminCredentials, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.put(`/admin/change-password`, { old_password, new_password: password });
      return fulfillWithValue({ ...data, stage: 'change-password' });
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const adminForgetPasswordRequest = createAsyncThunk(
  'auth/forgetPasswordRequest',
  async (email_address: string, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.post('/admin/forget-password', { email_address }, {
        headers: { "Content-type": "application/json" }
      });
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const adminPasswordReset = createAsyncThunk(
  'auth/agentPasswordReset',
  async ({ password, token }: { password: string, token: string }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.post('/admin/change-password', { password, token }, {
        headers: { "Content-type": "application/json" }
      });
      return fulfillWithValue({ ...data, stage: 'change-password' });
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const verifyResetToken = createAsyncThunk(
  'auth/verifyResetToken',
  async (token: string, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.post('/admin/forget-password/verify-token', { token }, {
        headers: { "Content-type": "application/json" }
      });
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const adminLogin = createAsyncThunk(
  'auth/login',
  async (credentials: AdminCredentials, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.post('/admin/login', credentials);
      dispatch(setAdmin(data));
      return fulfillWithValue({ ...data, stage: 'login' });
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const adminLogout = createAsyncThunk(
  'auth/logout',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {

    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);
