import { createSlice } from '@reduxjs/toolkit';
import { ADMIN_INFO_KEY, ADMIN_TOKEN_KEY } from 'src/contants';
import { AuthState } from 'src/models/store';
import { asyncIsFulfilled, asyncIsPending, asyncIsRejected } from '../asyncConfig';
import {
  addAdminAccount, adminLogin, changeAdminActiveness, changeAdminPassword,
  deleteAdminById
} from './authService';

const initialState: AuthState = {
  message: '',
  admin: null,
  status: null,
  stage: null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAdmin: (state, action) => {
      state.admin = action.payload.admin;
      localStorage.setItem(ADMIN_INFO_KEY, JSON.stringify(action.payload?.admin));
      localStorage.setItem(ADMIN_TOKEN_KEY, action.payload?.token);
    },
    getAdmin: (state) => {
      const admin = JSON.parse(localStorage.getItem(ADMIN_INFO_KEY)!);
      state.admin = admin;
    },
    clearUser: (state) => {
      // localStorage.removeItem(ADMIN_INFO_KEY);
      // localStorage.removeItem(ADMIN_TOKEN_KEY);
      // state.admin = null;
    },
    clearAuthState: (state) => {
      state.status = null;
      state.message = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(addAdminAccount.pending, asyncIsPending)
    builder.addCase(addAdminAccount.rejected, asyncIsRejected)
    builder.addCase(addAdminAccount.fulfilled, asyncIsFulfilled)
    builder.addCase(deleteAdminById.pending, asyncIsPending)
    builder.addCase(deleteAdminById.rejected, asyncIsRejected)
    builder.addCase(deleteAdminById.fulfilled, asyncIsFulfilled)
    builder.addCase(adminLogin.pending, asyncIsPending)
    builder.addCase(adminLogin.rejected, asyncIsRejected)
    builder.addCase(adminLogin.fulfilled, asyncIsFulfilled)
    builder.addCase(changeAdminActiveness.pending, asyncIsPending)
    builder.addCase(changeAdminActiveness.rejected, asyncIsRejected)
    builder.addCase(changeAdminActiveness.fulfilled, asyncIsFulfilled)
    builder.addCase(changeAdminPassword.pending, asyncIsPending)
    builder.addCase(changeAdminPassword.rejected, asyncIsRejected)
    builder.addCase(changeAdminPassword.fulfilled, asyncIsFulfilled)
  }
});

export const { setAdmin, getAdmin, clearUser, clearAuthState } = authSlice.actions;
export default authSlice.reducer;