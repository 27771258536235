import { ChangeEvent, useEffect, useRef, useState } from 'react';

import { NavLink, useNavigate } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  TextField,
  Typography
} from '@mui/material';

import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import AccountBoxTwoToneIcon from '@mui/icons-material/Password';
import { styled } from '@mui/material/styles';
import { notify } from 'reapop';
import AlertDialog from 'src/components/AlertDialog';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { AdminCredentials } from 'src/models/admin';
import { changeAdminPassword } from 'src/store/auth/authService';
import { clearAuthState, clearUser, getAdmin } from 'src/store/auth/authSlice';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.3)}
`
);

function HeaderUserbox() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { admin, status, message, stage } = useAppSelector((state) => state.auth);
  const [credentials, setCredentials] = useState<AdminCredentials>({
    password: '',
    old_password: '',
    confirm_password: ''
  })
  const [showDialog, setShowDialog] = useState({
    changePasscode: false
  })
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const user = {
    name: `${admin?.fullname}`,
    avatar: '/dapscnect_logo.png',
    jobtitle: `ADMIN (${admin?.admin_role.toUpperCase()})`
  };

  useEffect(() => {
    if (status === 'fulfilled' && stage === 'change-password') {
      dispatch(notify(message, 'success'))
      setTimeout(() => {
        dispatch(notify('Please login with your new credential', 'info'));
        dispatch(clearUser());
        navigate('/login');
      }, 600);
    } else if (status === 'rejected') {
      dispatch(notify(message, 'error'))
    }
    // eslint-disable-next-line
    return () => {
      dispatch(clearAuthState())
    }
    // eslint-disable-next-line
  }, [status]);

  useEffect(() => {
    dispatch(getAdmin());
    // eslint-disable-next-line
  }, []);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleUserLogout = () => {
    dispatch(clearUser());
    navigate('/login');
  }

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  }

  return (
    <>
      {showDialog.changePasscode && <AlertDialog
        state={status}
        title='Change Admin Password'
        body={<>
          <TextField
            margin="normal"
            required
            fullWidth
            value={credentials.old_password}
            onChange={handleInputChange}
            name="old_password"
            label="Current Password"
            type='password'
          />
          <TextField
            margin="normal"
            required
            fullWidth
            value={credentials.password}
            onChange={handleInputChange}
            name="password"
            label="New Password"
            type='password'
          />
          <TextField
            margin="normal"
            required
            fullWidth
            value={credentials.confirm_password}
            onChange={handleInputChange}
            name="confirm_password"
            label="Confirm Password"
            type='password'
          />
        </>}
        open={showDialog.changePasscode}
        onProceed={() => {
          const { password, confirm_password } = credentials;
          if (password !== confirm_password) {
            return dispatch(notify('Both passwords should match', 'error'))
          } else if (password.length <= 8)
            return dispatch(notify('Password should be more than 8 characters', 'error'))
          dispatch(changeAdminPassword(credentials))
        }}
        onClose={() => setShowDialog({ changePasscode: false })}
      />
      }
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar variant="rounded" alt={user.name} src={user.avatar} />
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user.jobtitle}
            </UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar variant="rounded" alt={user.name} src={user.avatar} />
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user.jobtitle}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          <ListItem button to="#" onClick={() => setShowDialog({ changePasscode: true })} component={NavLink}>
            <AccountBoxTwoToneIcon fontSize="small" />
            <ListItemText primary="Change Password" />
          </ListItem>
          {admin?.admin_role !== 'data-engineer' ? <ListItem
            button
            to="/configurations"
            component={NavLink}
          >
            <AccountTreeTwoToneIcon fontSize="small" />
            <ListItemText primary="System Settings" />
          </ListItem> : <></>}
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button onClick={handleUserLogout} color="primary" fullWidth>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
