import ApplicationIcon from '@mui/icons-material/Approval';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import FormIcon from '@mui/icons-material/FileCopy';
import ExtendedIcon from '@mui/icons-material/Folder';
import CLientIcon from '@mui/icons-material/Group';
import WellbeingIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import HelpIcon from '@mui/icons-material/HelpCenterOutlined';
import AgentsIcon from '@mui/icons-material/People';
import AdminIcon from '@mui/icons-material/PeopleAltSharp';
import BlogPostIcon from '@mui/icons-material/SnippetFolderOutlined';
import JobIcon from '@mui/icons-material/Work';
import { AdminRoles } from 'src/models/admin';


export interface MenuItem {
  link?: string;
  icon?: any;
  badge?: string;
  badgeTooltip?: string;
  access_roles: AdminRoles[]
  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: 'General',
    items: [
      {
        name: 'Dashboard',
        access_roles: ['system-admin', 'super-admin'],
        icon: DashboardTwoToneIcon,
        link: '/general/dashboard'
      },
      {
        name: 'Issues Reported',
        access_roles: ['system-admin', 'super-admin'],
        icon: HelpIcon,
        link: '/general/issues'
      },
      {
        name: 'Well Being',
        access_roles: ['system-admin', 'super-admin'],
        icon: WellbeingIcon,
        link: '/general/well-being'
      },
      {
        name: 'Blog Posts',
        access_roles: ['system-admin', 'super-admin'],
        icon: BlogPostIcon,
        link: '/general/blogs'
      }
    ]
  },
  {
    heading: 'Users',
    items: [
      {
        name: 'Admins',
        icon: AdminIcon,
        access_roles: ['system-admin', 'super-admin'],
        link: '/user/admins/all'
      },
      {
        name: 'Agents',
        icon: AgentsIcon,
        access_roles: ['system-admin', 'super-admin'],
        link: '/user/agents/all'
      },
      {
        name: 'Clients',
        icon: CLientIcon,
        access_roles: ['system-admin', 'super-admin', 'data-engineer'],
        link: '/user/clients/all'
      },
      {
        name: 'Influencers',
        icon: CLientIcon,
        access_roles: ['system-admin', 'super-admin', 'data-engineer'],
        link: '/user/influencers/all'
      },
    ]
  },
  {
    heading: 'Applications',
    items: [
      {
        name: 'Jobs',
        access_roles: ['system-admin', 'super-admin', 'data-engineer'],
        icon: JobIcon,
        link: '/application/jobs'
      },
      {
        name: 'Forms',
        access_roles: ['system-admin', 'super-admin', 'data-engineer'],
        icon: FormIcon,
        items: [
          {
            name: 'Documents Forms',
            icon: ExtendedIcon,
            access_roles: ['system-admin', 'super-admin', 'data-engineer'],
            link: '/application/forms/document-forms'
          },
          {
            name: 'KYC Forms',
            icon: ExtendedIcon,
            access_roles: ['system-admin', 'super-admin', 'data-engineer'],
            link: '/application/forms/kyc'
          },
          {
            name: 'KYC Review Requests',
            icon: DocumentScannerIcon,
            access_roles: ['system-admin', 'super-admin', 'data-engineer'],
            link: '/application/forms/kyc-review-requests'
          },
        ]
      },
      {
        name: 'Requests',
        icon: ApplicationIcon,
        access_roles: ['system-admin', 'super-admin', 'data-engineer'],
        link: '/application/requests'
      },
    ]
  },
];

export default menuItems;
