import { createSlice } from '@reduxjs/toolkit';
import { ApplicationState } from 'src/models/store';
import { asyncIsFulfilled, asyncIsPending, asyncIsRejected } from '../asyncConfig';
import {
  changeApplicationFormActiveness,
  changeKYCFormActiveness,
  deleteApplicationForm,
  deleteKYCForm,
  deleteRequestById,
  getAllApplicationForms,
  getAllJobs,
  getApplicationFormById,
  getClientApplicationRequests, getKYCById, getKYCQuestionnaire, getKYCRequestedReviews, rejectJobApplication, saveApplicationForm, saveKYCQuestionnaire, submitKYCRequestedReviews, updateApplicationForm, updateKYCQuestionnaire, uploadUserRequestDocument, verifyJobApplication
} from './applicationService';

const initialState: ApplicationState = {
  message: '',
  status: null,
  forms: [],
  requests: [],
  jobs: [],
  questionnaires: [],
  kyc_client_docs: [],
}

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    clearApplicationState: (state) => {
      state.status = null;
      state.message = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(saveKYCQuestionnaire.pending, asyncIsPending)
    builder.addCase(saveKYCQuestionnaire.rejected, asyncIsRejected)
    builder.addCase(saveKYCQuestionnaire.fulfilled, asyncIsFulfilled)
    builder.addCase(submitKYCRequestedReviews.pending, asyncIsPending)
    builder.addCase(submitKYCRequestedReviews.rejected, asyncIsRejected)
    builder.addCase(submitKYCRequestedReviews.fulfilled, asyncIsFulfilled)
    builder.addCase(deleteKYCForm.pending, asyncIsPending)
    builder.addCase(deleteKYCForm.rejected, asyncIsRejected)
    builder.addCase(deleteKYCForm.fulfilled, asyncIsFulfilled)
    builder.addCase(changeKYCFormActiveness.pending, asyncIsPending)
    builder.addCase(changeKYCFormActiveness.rejected, asyncIsRejected)
    builder.addCase(changeKYCFormActiveness.fulfilled, asyncIsFulfilled)
    builder.addCase(updateKYCQuestionnaire.pending, asyncIsPending)
    builder.addCase(updateKYCQuestionnaire.rejected, asyncIsRejected)
    builder.addCase(updateKYCQuestionnaire.fulfilled, asyncIsFulfilled)
    builder.addCase(saveApplicationForm.pending, asyncIsPending)
    builder.addCase(saveApplicationForm.rejected, asyncIsRejected)
    builder.addCase(saveApplicationForm.fulfilled, asyncIsFulfilled)
    builder.addCase(deleteRequestById.pending, asyncIsPending)
    builder.addCase(deleteRequestById.rejected, asyncIsRejected)
    builder.addCase(deleteRequestById.fulfilled, asyncIsFulfilled)
    builder.addCase(uploadUserRequestDocument.pending, asyncIsPending)
    builder.addCase(uploadUserRequestDocument.rejected, asyncIsRejected)
    builder.addCase(uploadUserRequestDocument.fulfilled, asyncIsFulfilled)
    builder.addCase(deleteApplicationForm.pending, asyncIsPending)
    builder.addCase(deleteApplicationForm.rejected, asyncIsRejected)
    builder.addCase(deleteApplicationForm.fulfilled, asyncIsFulfilled)
    builder.addCase(changeApplicationFormActiveness.pending, asyncIsPending)
    builder.addCase(changeApplicationFormActiveness.rejected, asyncIsRejected)
    builder.addCase(changeApplicationFormActiveness.fulfilled, asyncIsFulfilled)
    builder.addCase(updateApplicationForm.pending, asyncIsPending)
    builder.addCase(updateApplicationForm.rejected, asyncIsRejected)
    builder.addCase(updateApplicationForm.fulfilled, asyncIsFulfilled)
    builder.addCase(verifyJobApplication.pending, asyncIsPending)
    builder.addCase(verifyJobApplication.rejected, asyncIsRejected)
    builder.addCase(verifyJobApplication.fulfilled, asyncIsFulfilled)
    builder.addCase(rejectJobApplication.pending, asyncIsPending)
    builder.addCase(rejectJobApplication.rejected, asyncIsRejected)
    builder.addCase(rejectJobApplication.fulfilled, asyncIsFulfilled)
    builder.addCase(getClientApplicationRequests.pending, asyncIsPending)
    builder.addCase(getClientApplicationRequests.rejected, asyncIsRejected)
    builder.addCase(getClientApplicationRequests.fulfilled, (state, action) => {
      state.status = null;
      state.requests = action.payload;
    })
    builder.addCase(getAllJobs.pending, asyncIsPending)
    builder.addCase(getAllJobs.rejected, asyncIsRejected)
    builder.addCase(getAllJobs.fulfilled, (state, action) => {
      state.status = null;
      state.jobs = action.payload;
    })
    builder.addCase(getAllApplicationForms.pending, asyncIsPending)
    builder.addCase(getAllApplicationForms.rejected, asyncIsRejected)
    builder.addCase(getAllApplicationForms.fulfilled, (state, action) => {
      state.status = null;
      state.forms = action.payload;
    })
    builder.addCase(getApplicationFormById.pending, asyncIsPending)
    builder.addCase(getApplicationFormById.rejected, asyncIsRejected)
    builder.addCase(getApplicationFormById.fulfilled, (state, action) => {
      state.status = null;
      state.forms = [action.payload];
    })
    builder.addCase(getKYCQuestionnaire.pending, asyncIsPending)
    builder.addCase(getKYCQuestionnaire.rejected, asyncIsRejected)
    builder.addCase(getKYCQuestionnaire.fulfilled, (state, action) => {
      state.status = null;
      state.questionnaires = action.payload;
    })
    builder.addCase(getKYCById.pending, asyncIsPending)
    builder.addCase(getKYCById.rejected, asyncIsRejected)
    builder.addCase(getKYCById.fulfilled, (state, action) => {
      state.status = null;
      state.questionnaires = [action.payload];
    })
    builder.addCase(getKYCRequestedReviews.pending, asyncIsPending)
    builder.addCase(getKYCRequestedReviews.rejected, asyncIsRejected)
    builder.addCase(getKYCRequestedReviews.fulfilled, (state, action) => {
      console.log('Fulfilled', state, action.payload);
      state.status = null;
      state.kyc_client_docs = action.payload;
    })
  }
});

export const { clearApplicationState } = applicationSlice.actions;
export default applicationSlice.reducer;